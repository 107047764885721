import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";
import pathBuilder from "../../utils/pathBuilder";
import dateFormat from "dateformat";
export const CardContainer = styled.div`
  color: ${(props) => props.theme.lighterTwo};
  padding: ${theme.halfGutter} 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  border-radius: 10px;
  margin-top: 50px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.halfUp},
    ${(props) => props.theme.primary}
  );
  box-shadow: 5px 5px 7px ${(props) => props.theme.default_darkerOne},
    -2px -2px 4px ${(props) => props.theme.lighterOne};

  h1,
  h2,
  h3,
  h4 {
    margin-top: 0px;
    color: ${(props) => props.theme.lighterTwo};

    font-weight: 700;
  }
  span,
  .overline {
    color: ${(props) => props.theme.lighterTwo};
  }

  ${MEDIA.PHONE`
        flex-direction: column;

        h1,h2,h3 {
            font-size: 20px;
        }
        .overline {
            font-size: 10px;
            

        }
    `};
`;

export const ImageContainer = styled.div`
  width: 40%;
  margin: 0 ${theme.halfGutter};
  border-radius: 8px;
  img {
    max-width: 100%;
    height: auto;
    margin: 0px;
    padding: 0px;
    box-shadow: 5px 5px 7px ${(props) => props.theme.darkerOne},
      -2px -2px 4px ${(props) => props.theme.lighterOne};
  }

  ${MEDIA.PHONE`
        width: 60%;
    `};
`;
export const ContentContainer = styled.div`
  width: 60%;
  padding: ${theme.halfGutter};
  h3 {
    font-size: 1rem;
  }
  ${MEDIA.PHONE`
        width: 100%;
    `};
  .levelTag {
  }
  .publishDate {
    margin-top: 1rem;
    display: block;
    font-size: 0.7rem;
  }
`;
const PodcastGridItem = (props) => {
  const { node } = props;
  const datePublishedAt = dateFormat(node.published_at, "fullDate");

  return (
    <Link to={pathBuilder("en", "ContentfulPodcastEpisode", node.slug)}>
      <CardContainer>
        <ImageContainer>
          <img src={node.artwork_url} alt={node.title} />
        </ImageContainer>
        <ContentContainer>
          <span className="overline">Episode Number:{node.episode_number}</span>
          <h2>{node.title}</h2>
          <span className="levelTag">
            Good for: <strong>{node.tags.split(",")[0]}</strong>
          </span>
          <br />
          <span className="publishDate">Published on: {datePublishedAt}</span>
        </ContentContainer>
      </CardContainer>
    </Link>
  );
};

export default PodcastGridItem;
