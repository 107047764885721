import React from "react";
import { graphql } from "gatsby";
import PodcastTemplate from "../../templates/podcasts";

const environment = {
  lang: "en",
  path: "/en/podcast",
  altHrefLang: [
    {
      lang: "nl",
      path: "/podcast",
    },
    {
      lang: "en",
      path: "/en/podcast",
    },
  ],

  theme: "purple",
};

const podcastsEn = (props) => {
  return <PodcastTemplate {...props} environment={environment} />;
};

export default podcastsEn;

export const query = graphql`
  query allBuzzsproutPodcastEn {
    podcasts: allBuzzsproutPodcastEpisode(
      sort: { fields: [published_at], order: [DESC] }
    ) {
      edges {
        node {
          id
          title
          audio_url
          artwork_url
          description
          summary
          artist
          tags
          published_at
          duration
          hq
          guid
          episode_number
          season_number
          total_plays
          buzzsproutId
          slug
        }
      }
    }
    episodesContent: allContentfulPodcastEpisode {
      edges {
        node {
          title
          buzzsproutId
          slug
        }
      }
    }
  }
`;
