import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const PageIntroContainer = styled.div`
  h1,
  h2,
  h3,
  h4 {
    color: white;
    font-weight: 700;
    text-align: center;
  }
  span,
  p,
  a {
    color: white;
  }
  a {
    text-decoration: underline;
  }
  h1 {
    font-size: 4rem;
    margin: 3rem 0;
  }
  h2 {
    font-size: 2rem;
    margin: 3rem 0;
  }
  ${MEDIA.PHONE`
        h1{
            font-size: 3rem;
        }
        h2{
            font-size: 2rem;
        }

        padding: 16px 0;
    `}
  background-color: ${(props) => props.theme.primary};
  padding: 32px 0;
  margin-bottom: 32px;
  .container {
    max-width: ${theme.contentMaxWidth};
    margin: 0 auto;
  }
  .buttonArray {
    text-align: center;
  }
`;
export const ButtonPrimary = styled.div`
  display: inline-block;
  border-radius: 30px;
  padding: 10px 20px;
  margin: 10px;
  background: linear-gradient(
    135deg,
    ${(props) => props.theme.default_lighterTwo},
    ${(props) => props.theme.default_lighterOne}
  );

  box-shadow: 5px 5px 7px ${(props) => props.theme.darkerOne},
    -2px -2px 4px ${(props) => props.theme.lighterOne};

  color: ${(props) => props.theme.darkerTwo};
  font-weight: 700;
`;
