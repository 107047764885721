import styled from "styled-components";
import { theme } from "../../theme/variables";
import MEDIA from "../../theme/helpers/mediaTemplates";

export const Container = styled.div`
  padding: ${theme.halfGutter};
  max-width: ${theme.contentMaxWidth};
  margin: auto;
`;
export const ContainerWide = styled.div`
  padding: ${theme.halfGutter};
  max-width: ${theme.contentWideMaxWidth};
  margin: auto;
`;
type Row = {
  align?: string;
}
export const Row = styled.div<Row>`
  margin: calc(-${theme.halfGutter} / 2);
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.align};
`;

type Column = {
  w: number;
  mw: number;
}
export const Column = styled.div<any>`
  padding: calc(${theme.halfGutter} / 2);
  width: ${(props) => props.w}%;
  display: inline-block;
  ${MEDIA.TABLET`
		min-width: 100%;
		min-width: ${(props: any) => props.mw}%;
    `};
`;
export const ButtonPrimary = styled.button`
  padding: calc(0.5 * ${theme.halfGutter});
  background-color: ${theme.colorPrimary};
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  box-shadow: 0px 0px 5px #00000032;
  margin-right: ${theme.halfGutter};
  border: none;
  border-radius: 0px;
`;

export const ButtonSecondary = styled.button`
  padding: calc(0.5 * ${theme.halfGutter});
  border-radius: 0px;
  background-color: transparent;
  text-transform: uppercase;
  color: white;
  letter-spacing: 1px;
  border: 1px solid white;
  box-shadow: 0px 0px 5px #00000032;
  margin-right: ${theme.halfGutter};
`;
export const HiddenList = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
`;
export const HiddenListItem = styled.li`
  list-style: none;
  margin: 0px;
  padding: 0px;
`;
