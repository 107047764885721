import React from "react";
// import pathBuilder from "../../../utils/pathBuilder"
import { Container } from "../../components/System/Grids";
// import PageIntro from "../../../components/PageIntro/PageIntro"
import Layout from "../../components/Layout/Layout";
import podcastsInfo from "../../../data/podcastsInfo";
// import { Link } from "gatsby"
import PodcastGridItem from "../../components/PodcastGridItem/PodcastGridItem";
import { PageIntroContainer, ButtonPrimary } from "./PodcastsTemplate.css";
import Helmet from "react-helmet";

const PodcastTemplate = (props) => {
  const { podcasts } = props.data;
  const { environment } = props;
  const content = podcastsInfo[environment.lang];

  const seo = {};
  if (content.seo.description) {
    seo.description = content.seo.description;
  }
  if (content.seo.title) {
    seo.title = content.seo.title;
  } else {
    seo.title = content.hero.title;
  }
  environment.seo = seo;

  return (
    <Layout environment={environment}>
      <Helmet>
        <link
          type="application/rss+xml"
          rel="alternate"
          title="Learn Dutch with The Dutch Online Academy"
          href="https://feeds.buzzsprout.com/904798.rss"
        />
      </Helmet>
      {content ? (
        <PageIntroContainer>
          <div className="container">
            {content.hero.title ? <h1>{content.hero.title}</h1> : null}
            {content.hero.subtitle ? <h2>{content.hero.subtitle}</h2> : null}
            <div className="buttonArray">
              {content.links.spotifyFeed ? (
                <a
                  href={content.links.spotifyFeed}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button primary"
                >
                  <ButtonPrimary>Listen on Spotify</ButtonPrimary>
                </a>
              ) : null}
              {content.links.appleFeed ? (
                <a
                  href={content.links.appleFeed}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button primary"
                >
                  <ButtonPrimary>Listen on Apple Podcasts</ButtonPrimary>
                </a>
              ) : null}
              {content.links.googleFeed ? (
                <a
                  href={content.links.googleFeed}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="button primary"
                >
                  <ButtonPrimary>Listen on Google Podcasts</ButtonPrimary>
                </a>
              ) : null}
            </div>
          </div>
        </PageIntroContainer>
      ) : null}

      <Container>
        {podcasts.edges.map(({ node }, index) => (
          <PodcastGridItem key={index} node={node}>
            {node.title}
          </PodcastGridItem>
        ))}
      </Container>
    </Layout>
  );
};

export default PodcastTemplate;
